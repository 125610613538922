.formRoot {
    input {
        color: white;
    }

    ::placeholder {
        color: #CCCCCC;
        opacity: 1; /* Firefox */
    }

    ::-ms-input-placeholder { /* Edge 12 -18 */
        color: #CCCCCC;
    }
}

.dataRoot {
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (min-width: 700px) {
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
}

.dataBox {
    display: flex;
    flex-direction: row;
    gap: .5rem;
    align-items: center;
}

.calculatorInfo {
    font-size: 1rem;
}

.paceInfo {
    font-size: 1rem;
}

.calculatorBtn {
    font-size: 1.2rem;
    border: .2rem;
    border-radius: .5rem;
    transition: all .3s ease-in;
    padding: .2rem .5rem;
}

.calculatorBtn:hover {
    border: 0;
    border-radius: .5rem;
    padding: .2rem .5rem;
    background-color: #51aaab;
}

.noMarginPadding {
    padding: 0;
    margin: 0;
}
