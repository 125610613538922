.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
    fill: white;
}

.App-main {
    background-color: #282c34;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 1vmin);
    color: white;
    padding: 1rem;
    overflow: hidden;

    @media (min-width: 500px) {
        padding: 2rem;
    }
}

.App-content {
    width: 100%;
    max-width: 1000px;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

input {
    padding: .5rem;
    border-radius: .25rem;
    color: white;
    background: #888;
}

input.error {
    background-color: #f44336;
}
